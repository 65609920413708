import { createGlobalStyle } from "styled-components";
import { Font } from "./Font";

export const GlobalStyle = createGlobalStyle`
html{
    height:100%; 
    position:relative;
    font-family:${Font.Inter}
}
body {
    height: 100%;
    line-height: 1.4;
}
p + p {
    margin-top: 16px;
}
`;
