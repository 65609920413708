import * as React from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../atoms/Button/PrimaryButton";
import { H2 } from "../../atoms/H2/H2";
import { AlertCard, Description, ProgressBar, Root, StatusIcon } from "./Alerts.styles";

export enum AlertType {
    Success = "success",
    Error = "error",
}

export interface IAlert {
    readonly type: AlertType;
    readonly message: string;
    readonly timeout: number;
}

interface IAuthContextProps {
    readonly alerts: Array<IAlert> | null;
    readonly pushAlert: ((alert: IAlert) => void) | null;
}
export const AlertsContext = createContext<IAuthContextProps>({ alerts: null, pushAlert: null });

export const AlertsContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [t] = useTranslation();
    const [activeAlert, setActiveAlert] = useState<IAlert | null>(null);

    const [alerts, setAlerts] = useState<Array<IAlert>>([]);
    const onPushAlert = useCallback(
        (alert: IAlert) => {
            setAlerts([...alerts, alert]);
        },
        [alerts, setAlerts]
    );
    const loadNextAlert = useCallback(() => {
        const nextAlert = alerts.shift() ?? null;
        if (nextAlert === null) {
            setActiveAlert(null);
            return;
        }
        setActiveAlert(nextAlert);
        setAlerts([...alerts]);
    }, [alerts, setActiveAlert, setAlerts]);

    const onCardClicked = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, []);
    useEffect(() => {
        if (activeAlert !== null) {
            const timer = setTimeout(() => {
                loadNextAlert();
            }, activeAlert.timeout);
            return () => {
                clearTimeout(timer);
            };
        }
        loadNextAlert();
    }, [activeAlert, loadNextAlert]);
    if (activeAlert !== null) {
        const { timeout, type } = activeAlert;
        const animationDuration = timeout / 1000;
        const heading = type === AlertType.Success ? t("alertModal.success") : t("alertModal.error");
        return (
            <AlertsContext.Provider value={{ alerts, pushAlert: onPushAlert }}>
                {children}
                <Root onClick={loadNextAlert}>
                    <AlertCard onClick={onCardClicked}>
                        <H2>{heading}</H2>
                        <StatusIcon type={type} />
                        <Description>{activeAlert.message}</Description>
                        <PrimaryButton autoFocus onClick={loadNextAlert}>
                            {t("alertModal.ok")}
                        </PrimaryButton>
                        <ProgressBar duration={animationDuration} />
                    </AlertCard>
                </Root>
            </AlertsContext.Provider>
        );
    }
    return <AlertsContext.Provider value={{ alerts, pushAlert: onPushAlert }}>{children}</AlertsContext.Provider>;
};
