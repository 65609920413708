export enum Color {
    Accent100 = "rgba(185, 76, 187, 1)",
    Accent10 = "rgba(185, 76, 187, 0.1)",
    Primary100 = "rgba(0, 112, 244, 1)",
    Primary80 = "#338DF6",
    Primary50 = "rgba(0, 112, 244, 0.5)",
    Primary10 = "#E6F1FE",
    Primary110 = "#0064DA",
    Background = "#FFFFFF",
    Foreground = "#000000",
    Neutral = "#CCCCCC",
    Neutral100 = "rgba(0, 0, 16, 1)",
    Neutral90 = "#35363A",
    Neutral60 = "#52555E",
    Neutral50 = "#767B87",
    Neutral40 = "#AFB4C5",
    Neutral30 = "#C4C9D9",
    Neutral20 = "#E7EAF4",
    Neutral10 = "#F5F6FB",
    Neutral0 = "#FFFFFF",
    Error100 = "#D63939",
    White100 = "rgba(255, 255, 255, 1)",
    White80 = "rgba(255, 255, 255, 0.8)",
    White50 = "rgba(255, 255, 255, 0.5)",
    White20 = "rgba(255, 255, 255, 0.2)",
    White10 = "rgba(255, 255, 255, 0.1)",
    Black10 = "rgba(0, 0, 0, 0.1)",
    Green100 = "#57BF40",
    A = "#FF8360",
    B = "#E8E288",
    C = "#7DCE82",
    D = "#3CDBD3",
    E = "#00FFF5",
    AboveFoldBackground = "#FFFFFF",
    LightGray = "#F5F5F5",
    DarkGray = "#E5E5E5",
    PurchaseYellow = "#FFD700",
}
