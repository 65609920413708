import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";
import * as React from "react";
import { AlertsContextProvider } from "../../Alerts/AlertsContext";

const posthogApiHost = process.env.GATSBY_REACT_APP_PUBLIC_POSTHOG_HOST ?? "";
const posthogApiKey = process.env.GATSBY_REACT_APP_PUBLIC_POSTHOG_KEY ?? "";

const options = {
    api_host: posthogApiHost,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
        },
    },
});

const GuardedPosthogProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const hasValidPosthogConfig = posthogApiHost !== "" && posthogApiKey !== "";
    if (hasValidPosthogConfig === false) {
        return <>{children}</>;
    }
    return (
        <PostHogProvider apiKey={posthogApiKey} options={options}>
            {children}
        </PostHogProvider>
    );
};

export const AppContext: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <GuardedPosthogProvider>
            <QueryClientProvider client={queryClient}>
                <AlertsContextProvider>{children}</AlertsContextProvider>
            </QueryClientProvider>
        </GuardedPosthogProvider>
    );
};
